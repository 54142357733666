$theme-colors: (
  primary: #8aacc8,
  secondary: #61798d,
  tertiary: #435461,
  gray: #5f5f5f,
  gray-light: #8f8f8f,
  gray-lighter: #b2b2b2,
  gray-lightest: #d2d2d2,
  black: #000000,
  primary-brd-rect: #8aacc8,
  secondary-brd-rect: #61798d,
  tertiary-brd-rect: #435461,
  gray-brd-rect: #5f5f5f,
  black-brd-rect: #000000,
  table-column-bg: #e2eaf1,
  danger: #dc3545,
  danger-light: #dc7a84,
  danger-lighter: #df9da4,
  danger-lightest: #dac3c5
);

$btn-border-radius: 300px !important;
$btn-border-width: 2px;
$link-color: #ffffff;

$btn-padding-x: 35px;
$btn-padding-x-sm: 25px;
$btn-padding-x-lg: 45px;

$btn-sizes: (sm, md, lg);

$screen-896-scale: 0.7;
$screen-768-scale: 0.7;
