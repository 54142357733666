@import "./custom-variables";

td,
div,
span,
p {
  letter-spacing: 0.05em;
  font-family: Roboto;
}

@for $i from 1 through 10 {
  $vHeight: $i * 10;

  .vh-#{$vHeight} {
    height: #{$vHeight}vh !important;
  }
}

.btn {

  &.active,
  &:hover {
    color: #ffffff !important;

    span {
      &.material-icons {
        color: #ffffff !important;
      }
    }
  }

  &[class*="brd-rect"] {
    border-radius: 5px !important;
  }

  &.content-center {
    &.no-icon {
      display: unset !important;
    }

    display: inline-flex !important;
    align-items: center;
  }

  @each $size in $btn-sizes {
    $i: index($btn-sizes, $size);

    &.btn-#{$size} {
      span {
        &.material-icons {
          font-size: #{($i * 5) + 20}px !important;
        }
      }
    }
  }
}

@each $color,
$value in $theme-colors {
  $disabledColor: mix(white, $value, 50%) !important;

  .btn {
    &.btn-#{$color} {
      span {
        color: white;

        &.material-icons {
          color: white;
        }
      }
    }

    &.btn-outline-#{$color} {
      &.bg-default-white:not(:hover) {
        background-color: white;
      }

      span {
        &.material-icons {
          color: $value;
        }
      }

      &.active {
        background-color: theme-color("secondary") !important;
        box-shadow: 0 0 6px blue;

        span {
          &.material-icons {
            color: white;
          }
        }
      }

      &.disabled,
      &:disabled {
        color: $disabledColor;
        border-color: mix(white, $value, 50%) !important;
        cursor: unset !important;

        &:hover {
          color: $disabledColor;
        }
      }
    }

    &.disabled,
    &:disabled {
      cursor: unset !important;
    }
  }
}

.btn {
  &.btn-circle {
    width: 50px;
    height: 50px;
    padding: 6px 0px;
    border-radius: 15px;
    text-align: center;
    font-size: 12px;
    line-height: 1.42857;
    cursor: pointer;
  }

  &.btn-rect {
    padding: 5px !important;
    border-radius: 5px !important;
  }

  &.btn-sm span.material-icons,
  .btn-group-sm > .btn span.material-icons {
    font-size: 16px !important;
  }

  &.btn-sm,
  .btn-group-sm > .btn {
    font-size: 0.75rem !important;
  }

  &.btn.content-center {
    justify-content: center;
  }

  @for $i from 1 through 100 {
    $width: $i * 5;

    &.btn-circle-#{$width} {
      padding: 8px;
      width: #{$width}px !important;
      height: #{$width}px !important;
    }
  }

  @for $i from 1 through 50 {
    $width: $i;

    &.btn-circle-#{$width*$width} {
      padding: #{$width}px;
      width: #{$width * $width}px !important;
      height: #{$width * $width}px !important;
    }
  }

  cursor: pointer;
}

.btn-group {
  .btn {
    border-radius: 0 !important;
    border-width: 2px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.1em;

    &:first-child {
      border-radius: 10px 0px 0px 10px !important;
    }

    &:last-child {
      border-radius: 0px 10px 10px 0px !important;
    }

    &.btn-sm {
      border-width: 1px;
      padding-top: 5px;

      &:first-child {
        border-radius: 5px 0px 0px 5px !important;
      }

      &:last-child {
        border-radius: 0px 5px 5px 0px !important;
      }
    }

    &.btn-lg {
      padding-top: 10px;
      border-width: 3px;

      &:first-child {
        border-radius: 15px 0px 0px 15px !important;
      }

      &:last-child {
        border-radius: 0px 15px 15px 0px !important;
      }
    }
  }
}

.modal-dialog {
  .modal-header {
    border-bottom-width: 0px;
  }

  .modal-footer {
    border-top-width: 0px;
  }
}

@each $color,
$value in $theme-colors {
  .dropdown.dropdown-light-#{$color}:not(.default) {
    display: inline !important;

    .btn,
    .btn-secondary,
    .btn:hover,
    .btn-secondary:hover {
      border-radius: 0px !important;
      -moz-border-radius: 0 !important;
      -webkit-border-radius: 0 !important;
      color: $value !important;
      border-bottom-width: 1px !important;
      border-bottom-color: $value !important;
      border-top-width: 0;
      border-left-width: 0;
      border-right-width: 0;
      background-color: transparent !important;
    }

    &.dropdown-sm {
      .btn {
        font-size: 12px;
      }
    }

    &.dropdown-md {
      .btn {
        font-size: 14px;
      }
    }

    &.dropdown-lg {
      .btn {
        font-size: 16px;
      }
    }

    &.dropdown-currency {
      .btn {
        padding-left: 10px !important;
        padding-right: 10px !important;
        padding-bottom: 0px !important;
        padding-top: 0px !important;
      }
    }
  }
}

@each $color,
$value in $theme-colors {
  .input-light-#{$color} {
    display: inline !important;
    border-radius: 0px !important;
    -moz-border-radius: 0 !important;
    -webkit-border-radius: 0 !important;
    border-bottom-width: 1px !important;
    border-bottom-color: $value !important;
    border-top-width: 0 !important;
    border-left-width: 0 !important;
    border-right-width: 0 !important;
    color: $value !important;
    background-color: transparent !important;
  }
}

.text-sm {
  font-size: 0.8rem !important;
}

.text-md {
  font-size: 1rem !important;
}

.text-lg {
  font-size: 1.2rem !important;
}

.text-transparent {
  color: transparent !important;
}

.text-opacity-50 {
  opacity: 0.25;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

input[type="text"]::-webkit-inner-spin-button,
input[type="text"]::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

/*radio button style*/
$radioSize: 18px;
$radioBorder: #435461;
$radioActive: #435461;

$radioColor1: #f4f4f4;
$radioColor2: #435461;

.radio {
  input[type="radio"] {
    position: absolute;
    opacity: 0;

    + .radio-label {
      &:before {
        content: "";
        background: $radioColor1;
        border-radius: 100%;
        border: 3px solid darken($radioColor1, 25%);
        display: inline-block;
        width: 1.4em;
        height: 1.4em;
        position: relative;
        margin-right: 0.5em;
        vertical-align: top;
        cursor: pointer;
        text-align: center;
        transition: all 250ms ease;
      }
    }

    &:checked {
      + .radio-label {
        &:before {
          background-color: $radioColor2;
          box-shadow: inset 0 0 0 4px $radioColor1;
        }
      }
    }

    &:focus {
      + .radio-label {
        &:before {
          outline: none;
          border-color: $radioColor2;
        }
      }
    }

    &:disabled {
      + .radio-label {
        &:before {
          box-shadow: inset 0 0 0 4px $radioColor1;
          border-color: darken($radioColor1, 25%);
          background: darken($radioColor1, 25%);
        }
      }
    }

    + .radio-label {
      &:empty {
        &:before {
          margin-right: 0;
        }
      }
    }
  }
}

@import "~bootstrap/scss/bootstrap";

table {
  &.table {
    $paddingLeftInitial: 100px;

    td {
      border-radius: 0;
      padding-top: 0rem;
      padding-bottom: 0rem;
      vertical-align: middle;
      color: theme-color("tertiary");
      line-height: 0.8em;
      height: 38px;

      .title {
        font-size: 1em !important;
        font-weight: 600 !important;
      }

      .title-sm {
        font-size: 0.8em !important;
        font-weight: 600 !important;
      }

      .sub-title {
        font-size: 0.8em !important;
      }

      .svg-inline--fa {
        color: theme-color("secondary");

        &:hover {
          color: darken(theme-color("secondary"), 20%);
        }
      }
    }

    thead.sub-header {
      background-color: #f6f8fa;
    }

    thead td {
      text-align: center;
      width: 65px;
      height: 28px;
      font-size: 0.9em;
      color: theme-color("secondary");
      padding-top: 0;
      padding-bottom: 0;
      font-weight: 500;
      background-color: theme-color("table-column-bg");
      text-transform: uppercase;

      &.action-button {
        min-width: 85px;
        text-align: center;
      }

      &:first-child {
        padding-left: 20px;
      }

      &.text-sm {
        font-size: 0.7em !important;
      }

      @for $i from 1 through 20 {
        $width: $i * 5;

        &.w-#{$width} {
          width: #{$width} + "%" !important;
        }
      }
    }

    &.hover {

      tr:hover,
      tr.checked {
        background-color: lighten(gray, 40%) !important;
        cursor: pointer;
      }
    }

    tbody {
      td {
        min-height: 77px;
        min-width: 77px;

        &:first-child {
          padding-left: 20px !important;
        }
      }

      td {
        &.action-button {
          padding-left: 20px;
          padding-right: 20px;

          &:last-child {
            padding-left: 0;
            padding-right: 0;
          }
        }

        .icon-wrap {
          min-width: 77px;
          height: 77px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;

          .svg-inline--fa {
            font-size: 1.75em !important;
          }

          &:hover {
            background-color: theme-color("gray-lightest");
          }
        }
      }
    }

    .section-title {
      padding-left: $paddingLeftInitial;
      text-transform: uppercase;
      border: none;
    }

    .row-title {
      width: 65px;
    }

    td.attribute-title {
      width: 65px;
      padding-left: 40px !important;

      &:first-child {
        padding-left: 40px !important;
      }
    }

    .body-cell {
      text-align: center;
      min-width: 160px;
    }

    .checkbox {
      padding: 0 !important;
    }
  }

  &.table-sm {
    font-size: 13px;

    th,
    td {
      padding: 7px 10px 7px 15px;
    }
  }

  &.table-md {
    font-size: 15px;

    th,
    td {
      padding: 5px 10px 5px 10px;
    }
  }

  &.table-lg {
    font-size: 18px;

    th,
    td {
      padding: 5px 10px 5px 10px;
    }
  }
}

@each $color,
$value in $theme-colors {
  table.custom-#{$color} {
    thead:first-child {

      td,
      th {
        font-weight: bold;
        border: none;
        background-color: transparent;
        color: mix(black, $value, 50%);
      }
    }

    th {
      vertical-align: middle;
      background-color: mix(white, $value, 80%);
      font-weight: 400;
      height: auto;
      color: mix(black, $value, 20%);
    }

    td {
      height: auto;
      color: mix(black, $value, 40%);
    }

    tbody tr:last-child {

      td,
      th {
        border-bottom: 1px solid #dee2e6;
      }
    }
  }
}

.dropdown-toggle.btn {
  background-color: white;
  border: 1px #dee6ed solid;
  border-radius: 5px !important;
}

.mw-1280 {
  max-width: 1280px !important;
}

.vertical-text {
  writing-mode: vertical-rl;
  transform: rotate(180deg);

  span {
    text-orientation: sideways-right;
  }
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-disable {
  pointer-events: none;
  opacity: 0.5;
}

.hover-text-bold-500:hover {
  font-weight: 500;
}

.text-bold-500 {
  font-weight: 500;
}

@for $i from 1 through 20 {
  $width: $i * 5;

  .rounded-#{$width} {
    border-radius: #{$width}px !important;
    -moz-border-radius: #{$width}px !important;
    -webkit-border-radius: #{$width}px !important;
  }

  .shadow-#{$width} {
    $v1: $width / 10;
    $v2: $v1 * 2;
    $shadowV: 0 #{$v1}rem #{$v2}rem rgba(0, 0, 0, 0.15) !important;
    box-shadow: $shadowV;
    -moz-box-shadow: $shadowV;
    -webkit-box-shadow: $shadowV;
  }
}

.spinner-box {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  height: 200px;
  width: 100%;
}

.no-data {
  display: flex;
  height: 200px;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-style: italic;
  color: theme-color("gray-lighter");
}

.form-group {
  .form-check {
    display: inline-flex;
    align-items: center;

    .form-check-input {
      margin-top: auto;
    }

    .form-check-label {
      color: theme-color("tertiary");
      font-size: 0.9em;
    }
  }
}

.proposal-list-container,
.client-list-container {
  padding-left: 25px;
  padding-right: 25px;
}

/***Iphone X***/
@media only screen and (min-device-width: 700px) and (max-device-width: 781px) and (-webkit-device-pixel-ratio: 1),
only screen and (min-device-width: 700px) and (max-device-width: 781px) and (-webkit-device-pixel-ratio: 2),
only screen and (min-device-width: 700px) and (max-device-width: 781px) and (-webkit-device-pixel-ratio: 3) {

  .proposal-list-container,
  .client-list-container {
    padding-left: 25px;
    padding-right: 25px;
  }
}

/***Iphone XS***/
@media only screen and (min-device-width: 782px) and (max-device-width: 895px) and (-webkit-device-pixel-ratio: 3) {

  .proposal-list-container,
  .client-list-container {
    padding-left: 25px;
    padding-right: 25px;
  }
}

/***Iphone XS MAX,XR***/
@media only screen and (min-device-width: 896px) and (max-device-width: 1024px) and (-webkit-device-pixel-ratio: 2),
only screen and (min-device-width: 896px) and (max-device-width: 1024px) and (-webkit-device-pixel-ratio: 3) {

  .proposal-list-container,
  .client-list-container {
    padding-left: 25px;
    padding-right: 25px;
  }
}

@import "../../../node_modules/react-vis/dist/style";
